import React, { useState } from 'react';
import { TDR } from 'tdr-common';
import styles from './EventCard.module.scss';
import { DynamicIcon } from '../icons';
import { motion } from 'framer-motion';
import ArrowDown2 from 'iconsax-react/dist/esm/ArrowDown2';
import ArrowUp2 from 'iconsax-react/dist/esm/ArrowUp2';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import exportedConfig from '../../common/config';

function formatTime(time: string): string {
	const [hours] = time.split(':').map(Number);
	const period = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
	return `${formattedHours}${period}`;
}

function formatBetweenTimes(betweenTimes: [string, string]): string {
	const [startTime, endTime] = betweenTimes;
	return `${formatTime(startTime)}-${formatTime(endTime)}`;
}
function formatDaysOfWeek(daysOfWeek: string[]): string {
	const fullNames: { [key: string]: string } = {
		mon: 'monday',
		tues: 'tuesday',
		wed: 'wednesday',
		thurs: 'thursday',
		fri: 'friday',
		sat: 'saturday',
		sun: 'sunday'
	};

	if (daysOfWeek.length === 1) {
		return fullNames[daysOfWeek[0]];
	}

	const daysOrder = ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'];

	const dayIndices = daysOfWeek.map(day => daysOrder.indexOf(day)).sort((a, b) => a - b);
	const isConsecutive = dayIndices.every((value, index) => index === 0 || value === dayIndices[index - 1] + 1);

	if (isConsecutive) {
		return `${daysOfWeek[0]}-${daysOfWeek[daysOfWeek.length - 1]}`;
	}

	return daysOfWeek.map(day => day.slice(0, 3)).join(', ');
}

export const EventCard = ({ event }: { event: TDR.Event }) => {
	const [clicked, setClicked] = useState(false);
	const handleClick = () => {
		setClicked(!clicked);
	};

	const imageVariants = {
		default: { height: 270, opacity: 1 },
		hover: { height: 185, opacity: 1 },
		clicked: { height: 0, opacity: 0 }
	};

	const descriptionVariants = {
		default: { height: 0, opacity: 0 },
		hover: { height: 36, opacity: 1 },
		clicked: { height: 'auto', opacity: 1 }
	};

	const buttonVariants = {
		default: { opacity: 0 }, // Button hidden and slightly below its position
		hover: { opacity: 1 }, // Button becomes visible and moves into view
		clicked: { opacity: 1 } // Visible on click as well
	};

	let filePath = `/images/events/${event.id}/coverImage.jpg`;
	if (exportedConfig.deployment !== 'production') {
		 filePath = `/staging/images/events/${event.id}/coverImage.jpg`;
	}


	return <motion.div
		onClick={handleClick}
		initial="default"
		whileHover={clicked ? 'clicked' : 'hover'} // Different behavior based on click state
		animate={clicked ? 'clicked' : 'default'}
		className={styles.wrapper}
	>
		<motion.img
			src={getCdnImageUri(filePath)}
			alt="Event Cover Image"
			className={styles.mediaContent}
			variants={imageVariants}
		/>
		<div className={styles.titleSection} >
			<div className={styles.title}>{event.displays[0].title}</div>
			<div className={styles.timeInfo}>
				{event?.condition?.value?.daysOfWeek?.length > 0 ? <div className={styles.tag}>
					{<DynamicIcon iconName={'Calendar'} size={20} color={'#94969C'}/>}
					{formatDaysOfWeek(event.condition.value.daysOfWeek)}
				</div> : null}
				{event?.condition?.value?.betweenTimes?.length > 0 && event?.condition?.value?.daysOfWeek?.length > 0 &&<div className={styles.tag}>•</div>}
				{event?.condition?.value?.betweenTimes?.length > 0 ?
					<div className={styles.tag}>
						{<DynamicIcon iconName={'Clock'} size={20} color={'#94969C'}/>}
						{formatBetweenTimes(event.condition.value.betweenTimes)}
					</div> : null}
			</div>
		</div>
		<motion.div className={styles.description} variants={descriptionVariants}>
			{event.displays[0].description}
		</motion.div>
		<motion.div
			style={{
				position: 'absolute',
				bottom: 0, // Always at the very bottom of the container
				left: '50%',
				transform: 'translateX(-50%)',
				textAlign: 'center',
				cursor: 'pointer',
				padding: '12px 0'
			}}
			variants={buttonVariants} // Use the buttonVariants for this element
		>
			{clicked ? <ArrowUp2 size="20" color="#CECFD2"/> : <ArrowDown2 size="20" color="#CECFD2"/>}
		</motion.div>
	</motion.div>;

};
