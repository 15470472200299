import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { TDR, toLuxon } from 'tdr-common';
import { useSearch } from '../../api/availability/search';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useTables } from '../../api/getRestaurantTables';
import { URLParams } from '../../common/types';
import { SquareIconButton } from '../../components/buttons/SquareIconButton';
import { Header } from '../../components/checkout/Header';
import { DiscardChanges } from '../../components/dialog/controlled-dialog/DiscardChanges';
import { NoMatchingTables } from '../../components/dialog/controlled-dialog/NoMatchingTables';
import CarouselCard from '../../components/inventory-carousel/CarouselCard';
import { CarouselSkeleton } from '../../components/inventory-carousel/CarouselSkeleton';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import { ModifyBookingFormData } from '../../routes/BookingGuard';

import layoutStyles from '../../layouts/ModificationLayout.module.scss';
import styles from './ChangeSeatingPage.module.scss';

export const ChangeSeatingPage = () => {
	const navigate = useNavigate();
	const { reservationId } = useParams<URLParams>();

	const { data: reservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);
	const { data: tables, isLoading } = useTables(reservation.restaurantId);

	const { setNewInvoice } = useModificationFlowState();

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [hasNoMatchingTables, setHasNoMatchingTables] = useState(false);

	const methods = useFormContext<ModifyBookingFormData>();

	const searchFilters: SearchFilters = {
		groupSize: methods.watch('guests'),
		date: toLuxon(methods.watch('epochInSeconds'), restaurant.timezone).toFormat('yyyy-MM-dd'),
		time: toLuxon(methods.watch('epochInSeconds'), restaurant.timezone).toFormat('HH:mm')
	};

	const { results, isLoading: isSearching } = useSearch({
		params: searchFilters,
		restaurantId: restaurant.id,
		space: tables
	});

	const handleItemClick = (item: TDR.Table) => {
		methods.setValue('tableId', item.id, { shouldDirty: true });
		navigate('details');
	};

	const resetFormAndExit = () => {
		methods.reset();
		setNewInvoice(undefined);
		navigate(`/booking/${reservationId}`);
	};

	const handleExitClick = () => {
		if (methods.formState.isDirty) {
			setIsConfirmOpen(true);
		}
		else {
			resetFormAndExit();
		}
	};

	const handleConfirmExit = () => {
		resetFormAndExit();
		setIsConfirmOpen(false);
	};

	const handleCancelExit = () => {
		setIsConfirmOpen(false);
	};

	const handleBackClick = () => {
		navigate(-1);
	};

	// Only show available tables for currently booked time (that are not the currently booked table)
	const searchResultTables = tables.filter(
		(table) => results?.metadata.time.includes(table.id) && table.id !== reservation.tableId
	);

	// Only allow modification to a table of the same booking type (regular vs. large group), and filter out the current table
	const matchingTables = searchResultTables.filter(
		(table) =>
			table.id !== reservation.tableId &&
      (reservation.isLargeGroup ? table.supportLargeGroup : !table.supportLargeGroup)
	);

	useEffect(() => {
		if (isLoading || isSearching) {
			return;
		}

		if (matchingTables.length === 0) {
			setHasNoMatchingTables(true);
		}
	}, [matchingTables, isLoading, isSearching]);

	return (
		<>
			<Header
				title='Seat Selection'
				subtitle={restaurant.name}
				iconLeft={<SquareIconButton variant='back' />}
				iconLeftOnClick={handleBackClick}
				iconRight={<SquareIconButton variant='exit' />}
				iconRightOnClick={handleExitClick}
			/>

			<div className={layoutStyles.Body}>
				<ul className={styles.List}>
					{isLoading || isSearching ? (
						<CarouselSkeleton />
					) : (
						matchingTables.map((table) => (
							<CarouselCard
								key={table.id}
								restaurant={restaurant}
								table={table}
								searchFilters={searchFilters}
								available={!results?.resultMap?.[table.id]?.['conflict']}
								conflictingFilter={results?.resultMap?.[table.id]?.['conflict']?.includes('date') ? 'date' : 'time'}
								onClick={() => handleItemClick(table)}
								tableSearchResult={results?.resultMap?.[table.id]}
							/>
						))
					)}
				</ul>
			</div>

			<DiscardChanges
				open={isConfirmOpen}
				onClose={() => setIsConfirmOpen(false)}
				onDiscard={handleConfirmExit}
				onCancel={handleCancelExit}
			/>

			<NoMatchingTables
				open={hasNoMatchingTables}
				onDismiss={() => {
					setHasNoMatchingTables(false);
					navigate(`/booking/${reservationId}`);
				}}
			/>
		</>
	);
};
