import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toLuxon } from 'tdr-common';
import { useTableWithPolicies } from '../../../api/getTable';
import { useReleaseCheckoutSession } from '../../../api/releaseCheckoutSession';
import { analytics } from '../../../helpers/analytics';
import layoutStyles from '../../../layouts/CheckoutLayout.module.scss';
import { CheckoutGuardOutletContext } from '../../../routes/CheckoutGuard';
import AgreementCheckbox from '../../agreement-checkbox/AgreementCheckbox';
import { CTAButton } from '../../buttons';
import { TermsAndConditions } from '../../dialog/info-dialog/TermsAndConditions';
import { Divider } from '../../divider/Divider';
import { SubHeader } from '../../headers/SubHeader';
import PolicySection from '../../policies/PolicySection';
import PolicyItems from '../PolicyItems';
import { PolicyItemsLGB } from '../PolicyItemsLGB';
import { CheckoutCTALabel } from '../../../context/new/CheckoutContext';

export const Policies = () => {
	const navigate = useNavigate();

	const { invoice, checkoutSession, restaurant, featureFlags } = useOutletContext<CheckoutGuardOutletContext>();
	const [isCtaEnabled, setIsCtaEnabled]= useState<boolean>(false);
	const [isAgreementChecked, setIsAgreementChecked] = useState<boolean>(false);
	const { mutate: releaseCheckoutSession } = useReleaseCheckoutSession();

	const luxonStartDateTime = toLuxon(checkoutSession.time, restaurant.timezone);

	const { data: tableWithPolicies, isLoading } = useTableWithPolicies({
		id: checkoutSession.tableId,
		searchParams: {
			groupSize: checkoutSession.guests,
			date: luxonStartDateTime.toFormat('yyyy-LL-dd'),
			time: luxonStartDateTime.toFormat('HH:mm')
		},
		location: 'checkout'
	});

	const isPaidBooking = invoice?.total > 0;
	const withPaymentCopy = (
		<>
      I understand that the seating selection fee is not a deposit, and agree to the Policies and <TermsAndConditions />.
		</>
	);

	const noPaymentCopy = (
		<>
      I agree to the Policies and <TermsAndConditions />.
		</>
	);

	const label = <>{isPaidBooking ? withPaymentCopy : noPaymentCopy}</>;

	const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		setIsCtaEnabled(isChecked);
		setIsAgreementChecked(isChecked);
	};

	useEffect(() => {
		const handlePopState = () => {
			if (!featureFlags?.enableAddOns) {
				analytics.track('Checkout Session Exited');
				releaseCheckoutSession({ checkoutId: checkoutSession.id });
			}
		};

		window.addEventListener('popstate', handlePopState);
	}, []);

	useEffect(() => {
		setIsCtaEnabled(isAgreementChecked);
	}, [isAgreementChecked]);

	return (
		<>
			<div className={`${layoutStyles.Body} ${layoutStyles.BodyWithPadding}`}>
				<SubHeader title='Policies' isRequired />
				{!isLoading
					? tableWithPolicies?.policies?.length > 0
						? <PolicySection table={tableWithPolicies}/>
						: tableWithPolicies?.supportLargeGroup
							? <PolicyItemsLGB />
							: <PolicyItems />
					: null
				}
				<Divider />

				<AgreementCheckbox
					id='agreement-checkbox'
					checked={isAgreementChecked}
					label={label}
					onChange={handleCheckboxClick}
					required={true}
				/>
			</div>

			<div className={layoutStyles.Footer}>
				<CTAButton
					onClick={() => navigate('../details')}
					disabled={!isCtaEnabled}
					buttonText={CheckoutCTALabel.ACCEPT}
				/>
			</div>
		</>
	);
};
