import React from 'react';

import { useOutletContext } from 'react-router-dom';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import { BookNowButton } from '../buttons/BookNowButton';
import { PatioInfoSection } from '../callouts/PatioInfoSection';
import SearchResultPricing from '../price-label/PriceLabel';

import styles from './BookNowFooter.module.scss';

export interface BookNowFooterProps {
	priceAmount: number;
	displayUnit: string;
	disabled?: boolean;
}

export const BookNowFooter = ({ priceAmount, displayUnit, disabled = false }: BookNowFooterProps) => {
	const { table: selectedTable } = useOutletContext<TableGuardOutletContext>();
	const { restaurant, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();
	const hidePricing = !!featureFlags?.hideFreePricing;

	const text = selectedTable.supportLargeGroup ? hidePricing ? 'Proceed With Request' : 'Request' : 'Book Now';

	return (
		<>
			{selectedTable?.isPatio && <PatioInfoSection />}

			<div className={`${styles.Container} ${hidePricing ? '' : styles['Container--grid']}`}>
				{!hidePricing && <SearchResultPricing
					restaurant={restaurant}
					priceAmount={priceAmount}
					displayUnit={displayUnit}
				/>}

				<BookNowButton disabled={disabled} text={text} />
			</div>
		</>
	);
};
