import * as Sentry from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult, SetupIntentResult, StripeError } from '@stripe/stripe-js';
import { useIsMutating } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toLuxon } from 'tdr-common';
import {
	MUTATION_KEY as confirmFreeSessionKey,
	useConfirmFreeCheckoutSession
} from '../../../api/confirmFreeChecktouSession';
import { useTable } from '../../../api/getTable';
import { enableFastCheckout } from '../../../api/guest';
import { MUTATION_KEY as releaseSessionKey } from '../../../api/releaseCheckoutSession';
import { CheckoutCTALabel, useCheckoutContext } from '../../../context/new/CheckoutContext';
import { analytics } from '../../../helpers/analytics';
import { getCdnImageUri } from '../../../helpers/getCDNURI';
import { trackReservationCreation } from '../../../helpers/reservationEventTrackers';
import layoutStyles from '../../../layouts/CheckoutLayout.module.scss';
import { ConfirmationFailed } from '../../../pages/errors/ConfirmationFailed';
import { CheckoutGuardOutletContext } from '../../../routes/CheckoutGuard';
import { CTAButton } from '../../buttons';
import SecureEncryptInfo from '../../dialog/info-dialog/SecureEncryptInfo';
import { FilterIcons } from '../../filter-icons/FilterIcons';
import { InputValidationError } from '../../form-inputs/InputValidationError';
import { SubHeader } from '../../headers/SubHeader';
import {
	ImageCard,
	ImageCardAsset,
	ImageCardHeader,
	ImageCardHeaderPrimary,
	ImageCardHeaderTitle
} from '../../image-card/ImageCard';
import { AddOnOrderSummary } from '../AddOnOrderSummary';
import { ContactUsFooter } from '../ContactUsFooter';
import { DemoOrderSummary, OrderSummary } from '../OrderSummary';
import { PaymentInput } from '../PaymentInput';
import { PromoCodeInput } from '../PromoCodeInput';
import { ScrollDownButton } from '../ScrollDownButton';

export const Payment = () => {
	const { restaurant, featureFlags, checkoutSession, invoice } = useOutletContext<CheckoutGuardOutletContext>();
	const { checkoutState, isSaveContactChecked } = useCheckoutContext();

	const { mutateAsync: confirmFreeCheckoutSession } = useConfirmFreeCheckoutSession();

	const { data: table } = useTable(checkoutSession.tableId);

	const isReleasing = useIsMutating({
		mutationKey: [releaseSessionKey],
		exact: true
	});

	const isFreeBookingSubmitting = useIsMutating({
		mutationKey: [confirmFreeSessionKey],
		exact: true
	});

	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [stripeError, setStripeError] = useState('');
	const [isPaymentInfoComplete, setIsPaymentInfoComplete] = useState(false);

	const [freeBookingError, setFreeBookingError] = useState(false);

	const [isScrollButtonVisbile, setIsScrollButtonVisible] = useState(false);
	const scrollRef = useRef<HTMLDivElement>();

	const luxonStartDateTime = toLuxon(checkoutSession.time, restaurant.timezone);
	const displayDate = luxonStartDateTime.toFormat('MMMM dd');
	const displayTime = luxonStartDateTime.toFormat('h:mma');
	const addOns = checkoutSession.items.filter((item) => item.type === 'addon');

	const requiresCreditCard = restaurant.allowNoShowFees || invoice.total > 0;
	const isValid = !stripeError && (requiresCreditCard ? isPaymentInfoComplete : true);

	const imageSrc = getCdnImageUri(table.carouselImagePath, {
		width: 359,
		fit: 'crop',
		dpr: 2
	});

	useEffect(() => {
		if (!!checkoutState.stripePaymentMethod) {
			setIsPaymentInfoComplete(true);
		}
		else {
			setIsPaymentInfoComplete(false);
		}
	}, [checkoutState.stripePaymentMethod]);

	const handleError = (error: StripeError) => {
		try {
			if (!error) {
				setStripeError('');
				return;
			}
			if (error.type === 'card_error' || error.type === 'validation_error' || error.type === 'invalid_request_error') {
				setStripeError(error.message);
				analytics.track('Payment Input User Error', { reservationId: checkoutSession.id });
			}
			else {
				throw error;
			}
		}
		catch (e) {
			setStripeError('An unexpected error occurred, update your payment details and try again, later.');
			const eventId = Sentry.captureException(e);
			trackReservationCreation(checkoutState, checkoutSession, invoice, e.message, eventId);
		}
	};

	const handleFreeBooking = async () => {
		const result = await confirmFreeCheckoutSession(checkoutState.guestDetailsDTO);
		if (result.success) {
			trackReservationCreation(checkoutState, checkoutSession, invoice, null, null);
			navigate({ pathname: `/${restaurant.slug}/checkout/${checkoutSession.id}/confirmation` }, { replace: true });
		}
		else {
			setFreeBookingError(true);
		}
	};

	const handleSubmit = async (prefilledPayment: boolean) => {
		setIsSubmitting(true);
		try {
			// update fast checkout setting for guest
			if (isSaveContactChecked) {
				enableFastCheckout({ checkoutId: checkoutSession.id });
			}

			// Handles 100% promo code discounts
			if (!requiresCreditCard && invoice.total === 0) {
				await handleFreeBooking();
				return;
			}

			let stripeResponse: PaymentIntentResult | SetupIntentResult;

			const stripeConfirmation = invoice.total > 0 ? stripe.confirmPayment : stripe.confirmSetup;

			if (prefilledPayment) {
				stripeResponse = await stripeConfirmation({
					clientSecret: checkoutState.stripeClientSecret,
					redirect: 'if_required'
				});
			}
			else {
				stripeResponse = await stripeConfirmation({
					elements,
					redirect: 'if_required',
					confirmParams: {
						payment_method_data: {
							billing_details: {
								email: checkoutState.guest.email,
								name: checkoutState.guest.firstName + ' ' + checkoutState.guest.lastName,
								phone: checkoutState.guest.phone
							}
						}
					}
				});
			}

			const error = (stripeResponse as PaymentIntentResult)?.error;

			if (error) {
				handleError(error);
			}
			else {
				trackReservationCreation(checkoutState, checkoutSession, invoice);
				navigate({ pathname: `/${restaurant.slug}/checkout/${checkoutSession.id}/confirmation` }, { replace: true });
			}
		}
		catch (e) {
			const eventId = Sentry.captureException(e);
			trackReservationCreation(checkoutState, checkoutSession, invoice, e.message, eventId);
			setStripeError('An unexpected error occurred, update your payment details and try again, later.');
		}
		finally {
			setIsSubmitting(false);
		}
	};

	const isScrolledToBottom = () => {
		if (scrollRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
			return scrollTop + clientHeight >= scrollHeight;
		}
		else {
			return false;
		}
	};

	// Show scroll down button if user is inactive for 10 seconds
	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		const userEvents = ['mousemove', 'keydown', 'scroll'];

		const resetTimer = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				setIsScrollButtonVisible(true);
			}, 10000);
		};

		resetTimer();
		userEvents.forEach((event) => window.addEventListener(event, resetTimer));

		return () => {
			clearTimeout(timeoutId);
			userEvents.forEach((event) => window.removeEventListener(event, resetTimer));
		};
	}, []);

	if (freeBookingError) {
		return <ConfirmationFailed restaurantSlug={restaurant.slug} sessionId={checkoutSession.id} />;
	}

	return (
		<>
			<div className={layoutStyles.Body} ref={scrollRef}>
				<section className={layoutStyles.Section}>
					<FilterIcons groupSize={checkoutSession.guests} date={displayDate} time={displayTime} />
					{table ? (
						<ImageCard height={240} brightness={restaurant.brightness ?? 1} contrast={restaurant.contrast ?? 1}>
							<ImageCardAsset src={imageSrc} alt={table.name} />
							<ImageCardHeader>
								<ImageCardHeaderPrimary>
									<ImageCardHeaderTitle>
										<span>{table.name}</span>
									</ImageCardHeaderTitle>
								</ImageCardHeaderPrimary>
							</ImageCardHeader>
						</ImageCard>
					) : null}
					{addOns.length ? <AddOnOrderSummary addOns={addOns} currency={restaurant.currency} /> : null}
				</section>

				<section className={layoutStyles.Section}>
					<SubHeader title='Order Summary' />
					<PromoCodeInput checkoutSessionId={checkoutSession.id} invoiceId={invoice.id} />
					{featureFlags?.hideFreePricing ? <DemoOrderSummary /> : <OrderSummary />}
				</section>

				{requiresCreditCard && (
					<section className={layoutStyles.Section}>
						<SubHeader
							title='Payment Method'
							subtitle={
								<>
                  All transactions are secure and encrypted.
									<SecureEncryptInfo />
								</>
							}
							isRequired
						/>
						<PaymentInput
							stripePaymentMethod={checkoutState.stripePaymentMethod}
							promoCode={checkoutState.promoCode}
							setIsPaymentInfoComplete={setIsPaymentInfoComplete}
							onError={handleError}
						/>
					</section>
				)}

				<section className={layoutStyles.Section}>
					<ContactUsFooter />
				</section>

				<AnimatePresence>
					{isScrollButtonVisbile && !isScrolledToBottom() && (
						<motion.div
							style={{
								position: 'absolute',
								bottom: 72.5,
								width: '100%'
							}}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							onMouseLeave={() => setIsScrollButtonVisible(false)}
						>
							<ScrollDownButton scrollRef={scrollRef} />
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			<div className={layoutStyles.Footer}>
				{stripeError && <InputValidationError message={stripeError} />}
				<CTAButton
					buttonText={(checkoutState.isLargeGroup || featureFlags?.privateEvents) ? CheckoutCTALabel.SUBMIT : CheckoutCTALabel.CONFIRM}
					disabled={!isValid || !!isFreeBookingSubmitting || isSubmitting || !!isReleasing}
					loading={!!isFreeBookingSubmitting || isSubmitting || !!isReleasing}
					onClick={() => handleSubmit(!!checkoutState.stripePaymentMethod)}
				/>
			</div>
		</>
	);
};
