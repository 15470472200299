import * as Sentry from '@sentry/react';
import { useMutationState } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TDR, toLuxon } from 'tdr-common';
import { useSupportedGroupSizes } from '../../api/availability/availability';
import { useSearch } from '../../api/availability/search';
import { MUTATION_KEY, useDeletePendingMods } from '../../api/deletePendingModifications';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useRestaurantBasePolicies } from '../../api/getRestaurantBasePolicies';
import { useEntityWithAvailabilityRestrictingPolicies } from '../../api/getRestaurantBySlug';
import { useTables } from '../../api/getRestaurantTables';
import { useRestaurantTaxPolicies } from '../../api/getRestaurantTaxPolicies';
import { useTable } from '../../api/getTable';
import { useModifyReservation } from '../../api/modifyReservation';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { URLParams } from '../../common/types';
import { BookingDetailsSummary } from '../../components/booking-details-summary/BookingDetailsSummary';
import { CTAButton } from '../../components/buttons';
import { SquareIconButton } from '../../components/buttons/SquareIconButton';
import AvailabilityCalendarForMods from '../../components/calendar/AvailabilityCalendarForMods';
import { isToday, toLocalJsDate } from '../../components/calendar/Calendar.utils';
import { AlternativeBookingOption } from '../../components/callouts/AlternativeBookingOption';
import { Header } from '../../components/checkout/Header';
import { DiscardChanges } from '../../components/dialog/controlled-dialog/DiscardChanges';
import { NoAvailability } from '../../components/dialog/controlled-dialog/NoAvailability';
import { SameDayBookingInfo } from '../../components/dialog/controlled-dialog/SameDayBookingInfo';
import { PastHorizonInfo } from '../../components/dialog/info-dialog/PastHorizonInfo';
import { ModificationNotice } from '../../components/notice/ModificationNotice';
import {
	buildGroupSizeOptions,
	PartySizeOption,
	PartySizeSelector
} from '../../components/party-size-selector/PartySizeSelector';
import styles from '../../components/search/SearchContainer.module.scss';
import { Skeleton } from '../../components/skeleton/Skeleton';
import { TimeSelector } from '../../components/time-selector/TimeSelector';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import {
	getNewInvoiceForModifiedEpochOrGuestCount
} from '../../helpers/getNewInvoiceForModifiedEpoch';
import luxonToFirebaseTimestamp from '../../helpers/luxonToFirebaseTimestamp';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import { timeStringToEpoch } from '../../helpers/timeStringToEpoch';
import { ModifyBookingFormData } from '../../routes/BookingGuard';

const ModifcationSearchTables = () => {
	const navigate = useNavigate();

	const { reservationId } = useParams<URLParams>();

	const { newInvoice, setNewInvoice } = useModificationFlowState();

	const { data: reservation, refetch: refetchReservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);
	const { data: tables } = useTables(reservation.restaurantId);
	const { data: selectedTable } = useTable(reservation.tableId);
	const { data: restaurantPolicies } = useRestaurantBasePolicies(restaurant.id);
	const { data: taxPolicies } = useRestaurantTaxPolicies(restaurant);
	const { supportedGroupSizes, isLoading: isLoadingGroupSizes } = useSupportedGroupSizes({ tables });

	const modifyReservationMutation = useModifyReservation(reservationId);
	const deletePendingModsMutation = useDeletePendingMods(reservationId);

	const deletePendingMutationState = useMutationState({
		filters: { mutationKey: [MUTATION_KEY] },
		select: (mutation) => mutation.state.status
	})[0]; // useMutationState always returns an array

	const methods = useFormContext<ModifyBookingFormData>();

	const [tableIds, setTableIds] = useState<string[]>();
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [sameDayBookingOpen, setSameDayBookingOpen] = useState(false);
	const [noAvailabilityOpen, setNoAvailabilityOpen] = useState(false);
	const [pastHorizonOpen, setPastHorizonOpen] = useState(false);
	const [lastUnavailableDateClicked, setLastUnavailableDateClicked] = useState<DateTime>(null);

	const [groupSizeOptions] = buildGroupSizeOptions(supportedGroupSizes, null, false);

	const watchEpochInSeconds = methods.watch('epochInSeconds');
	const watchGroupSize = methods.watch('guests');

	const [selectedDate, setSelectedDate] = useState<string>(
		toLuxon(watchEpochInSeconds, restaurant.timezone).toFormat('yyyy-MM-dd')
	);
	const [selectedTime, setSelectedTime] = useState<string>(
		toLuxon(watchEpochInSeconds, restaurant.timezone).toFormat('HH:mm')
	);

	const [searchFilters, setSearchFilters] = useState({
		groupSize: methods.watch('guests'),
		date: selectedDate,
		time: selectedTime
	});

	const { data: entityWithPolicies } = useEntityWithAvailabilityRestrictingPolicies({
		slug: restaurant.slug,
		id: selectedTable?.id,
		searchParams: searchFilters
	});

	const { results, isLoading: isSearching } = useSearch({
		params: searchFilters,
		restaurantId: restaurant.id,
		space: tables,
		options: {
			releaseReservationId: reservationId
		}
	});

	const resetFormAndExit = () => {
		methods.reset();
		setNewInvoice(undefined);
		navigate(`/booking/${reservationId}`);
	};

	const handleExitClick = () => {
		if (methods.formState.isDirty) {
			setIsConfirmOpen(true);
		}
		else {
			resetFormAndExit();
		}
	};

	const handleBackClick = handleExitClick;

	const handleConfirmExit = () => {
		resetFormAndExit();
		setIsConfirmOpen(false);
	};

	const handleCancelExit = () => {
		setIsConfirmOpen(false);
	};

	const handleGroupSizeChange = (option: PartySizeOption) => {
		methods.setValue('guests', Number(option.value), { shouldDirty: true });
	};

	const handleSeatingChangeClick = () => {
		navigate(`/booking/${reservationId}/modify/seating-options`);
	};

	const handleReviewClick = async () => {
		// Payment required
		if (newInvoice.payable > 0) {
			const response = await modifyReservationMutation.mutateAsync({
				id: reservationId,
				price: newInvoice.total,
				guests: methods.getValues('guests'),
				time: luxonToFirebaseTimestamp(DateTime.fromSeconds(watchEpochInSeconds))
			});

			if (response.success) {
				methods.setValue('stripeClientSecret', response.clientSecret);
				methods.setValue('stripePaymentMethod', response.paymentMethod);
			}
			else {
				toast.error(response.message || 'Oops, something went wrong');
				Sentry.captureMessage(`modifyReservation Error: ${response.message}`);
			}
			navigate(`/booking/${reservationId}/payment`);
		}
		// Refund required
		else {
			navigate(`/booking/${reservationId}/refund`);
		}
	};

	const handleConfirmChangesClick = async () => {
		const updatedTimestamp = luxonToFirebaseTimestamp(DateTime.fromSeconds(watchEpochInSeconds));
		const response = await modifyReservationMutation.mutateAsync({
			id: reservationId,
			price: newInvoice.total,
			time: updatedTimestamp,
			guests: methods.getValues('guests')
		});
		if (response.success) {
			navigate(`/booking/${reservationId}`);
			trackReservationModifications({ reservation, itemsChanged: { time: updatedTimestamp, price: newInvoice.total } });
			toast.success('Changes saved successfully!');
		}
		else {
			const errorMessage = `modifyReservation Error: ${response.message}`;
			const sentryErrorID = Sentry.captureException(new Error(errorMessage));
			toast.error(response.message || 'Oops, something went wrong');
			trackReservationModifications({ reservation, errorMessage, sentryErrorID });
		}
	};

	const handleDateChange = async (date: DateTime, availabilityPercent: number) => {
		const isSameDay = isToday(toLocalJsDate(date)) && availabilityPercent === 0;

		if (isSameDay) {
			setSameDayBookingOpen(true);
		}
		else if (availabilityPercent > 0) {
			setSameDayBookingOpen(false);
			setNoAvailabilityOpen(false);
			setLastUnavailableDateClicked(null);
		}
		else {
			setNoAvailabilityOpen(true);
			setLastUnavailableDateClicked(date);
		}

		setSelectedDate(date.toFormat('yyyy-MM-dd'));
	};

	const handleTimeslotChange = async (newTime: string) => {
		const newEpochInSeconds = timeStringToEpoch(methods.getValues('epochInSeconds'), newTime, reservation.timezone);
		setSelectedTime(toLuxon(newEpochInSeconds, restaurant.timezone).toFormat('HH:mm'));
	};

	useEffect(() => {
		if (selectedDate && selectedTime) {
			const newEpochInSeconds = DateTime.fromFormat(`${selectedDate}__${selectedTime}`, 'yyyy-MM-dd__HH:mm', {
				zone: restaurant.timezone
			}).toSeconds();
			methods.setValue('epochInSeconds', newEpochInSeconds, { shouldDirty: true });
		}
		else if (selectedDate) {
			const newEpochInSeconds = DateTime.fromFormat(selectedDate, 'yyyy-MM-dd', {
				zone: restaurant.timezone
			}).toSeconds();
			methods.setValue('epochInSeconds', newEpochInSeconds, { shouldDirty: true });
		}
	}, [selectedDate, selectedTime]);

	useEffect(() => {
		if (results?.metadata?.date && !isLoading) {
			setTableIds(results.metadata.date);
		}
	}, [results?.metadata?.date]);

	useEffect(() => {
		setSearchFilters({
			groupSize: methods.watch('guests'),
			date: selectedDate,
			time: selectedTime
		});
	}, [selectedDate, selectedTime, methods]);

	useEffect(() => {
		if (noAvailabilityOpen) {
			setSelectedTime(null);
		}
	}, [noAvailabilityOpen]);

	useEffect(() => {
		if (!results || isSearching) {
			return;
		}

		if (selectedTime && results.metadata.time.length === 0) {
			setSelectedTime(null);
		}
	}, [results, isSearching, selectedTime, setSelectedTime]);

	useEffect(() => {
		if (reservation.status === TDR.Reservation.Status.Pending) {
			// Delete any pending changes and refetch reservation (in case user dropped off (or used browser back button) with pending changes while previously modifying their booking)
			if (reservation['expirationTask'] && reservation.pendingChanges) {
				deletePendingModsMutation.mutateAsync(reservationId).then(() => {
					refetchReservation();
					setNewInvoice(undefined);
				});
			}
		}
	}, [reservation, refetchReservation]);

	useEffect(() => {
		if (deletePendingMutationState === 'pending' || reservation.status === TDR.Reservation.Status.Pending) {
			return;
		}

		const calculateNewInvoice = async (newEpochInSeconds: number) => {
			const newInvoice = await getNewInvoiceForModifiedEpochOrGuestCount({
				restaurant,
				table: selectedTable,
				newEpochInSeconds,
				newGuestCount: watchGroupSize,
				existingReservation: reservation,
				basePolicies: [...(restaurantPolicies ?? []), ...(taxPolicies ?? [])]
			});

			setNewInvoice(newInvoice);
		};

		calculateNewInvoice(watchEpochInSeconds);
	}, [watchEpochInSeconds, watchGroupSize, deletePendingMutationState, reservation.status]);

	const isLoading = isSearching || isLoadingGroupSizes;
	const areFiltersComplete = !!searchFilters.groupSize && !!searchFilters.date && !!searchFilters.time;
	const currentTableSupportsNewParams =
    methods.formState.isDirty && !isLoading ? results?.metadata.time.includes(selectedTable.id) : true;
	const isPaymentStepRequired = newInvoice && newInvoice.payable !== 0;

	const ctaText = currentTableSupportsNewParams
		? isPaymentStepRequired
			? 'Review Change'
			: 'Confirm Change'
		: 'View Seating Options';

	const ctaOnClick = currentTableSupportsNewParams
		? isPaymentStepRequired
			? handleReviewClick
			: handleConfirmChangesClick
		: handleSeatingChangeClick;

	return (
		<div className={styles.Container}>
			<Header
				title='Booking Details'
				subtitle={restaurant.name}
				iconLeft={<SquareIconButton variant='back' />}
				iconLeftOnClick={handleBackClick}
				iconRight={<SquareIconButton variant='exit' />}
				iconRightOnClick={handleExitClick}
			/>

			{deletePendingMutationState === 'pending' || reservation.status === TDR.Reservation.Status.Pending ? (
				<Skeleton width='100%' height='100%' />
			) : (
				<section className={styles.Body} id='scrollableArea'>
					<PartySizeSelector
						searchFilters={searchFilters}
						onChange={handleGroupSizeChange}
						options={groupSizeOptions}
						defaultValue={searchFilters?.groupSize || undefined}
						analyticsOption={
							selectedTable
								? { searchFlow: 'find-a-table' }
								: {
									tablesShown: results?.metadata?.totalAvailable
								}
						}
					/>

					<AvailabilityCalendarForMods
						tables={tables}
						restaurant={restaurant}
						disabled={searchFilters.groupSize === undefined}
						onChange={handleDateChange}
						filters={searchFilters}
					/>

					<div id={HASH_ELEMENT_IDS.timeslots}>
						{!tableIds || !selectedDate || results?.metadata?.time.length === 0 ? null : (
							<TimeSelector
								restaurant={restaurant}
								existingReservationId={reservationId}
								table={null}
								searchFilters={searchFilters}
								tableIds={tableIds}
								onTimeslotChange={(value) => {
									handleTimeslotChange(value);
								}}
							/>
						)}
					</div>

					<AlternativeBookingOption restaurant={restaurant} />
				</section>
			)}

			<footer className={styles.Footer}>
				<ModificationNotice
					variant={
						areFiltersComplete && !currentTableSupportsNewParams
							? 'seatingChange'
							: areFiltersComplete && isPaymentStepRequired && !isLoading
								? 'priceChange'
								: null
					}
				/>

				<BookingDetailsSummary searchFilters={searchFilters} />

				<CTAButton
					variant='transparent'
					buttonText={ctaText}
					onClick={ctaOnClick}
					disabled={
						isLoading ||
            !methods.formState.isDirty ||
            !selectedTime ||
            modifyReservationMutation.isPending ||
            deletePendingMutationState === 'pending' ||
						(currentTableSupportsNewParams && !newInvoice)
					}
					loading={isLoading || modifyReservationMutation.isPending}
				/>
			</footer>

			<DiscardChanges
				open={isConfirmOpen}
				onClose={() => setIsConfirmOpen(false)}
				onDiscard={handleConfirmExit}
				onCancel={handleCancelExit}
			/>

			<SameDayBookingInfo
				isOpen={sameDayBookingOpen}
				onClose={() => setSameDayBookingOpen(false)}
				restaurant={restaurant}
			/>

			<PastHorizonInfo
				entityWithPolicies={entityWithPolicies}
				isOpen={pastHorizonOpen}
				onClose={() => setPastHorizonOpen(false)}
			/>

			<NoAvailability
				isOpen={noAvailabilityOpen}
				onClose={() => setNoAvailabilityOpen(false)}
				date={lastUnavailableDateClicked}
				groupSize={searchFilters.groupSize}
				restaurant={restaurant}
			/>
		</div>
	);
};

export default ModifcationSearchTables;
