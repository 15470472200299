import React, { ReactNode, useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { CAROUSEL_SCROLL_POSITION_KEY } from '../common/constants';
import { BookingOptionsHeader, BookWithAlternativePlatform, BookWithTablz } from '../components/booking-options';
import { useScreenSize } from '../hooks/useScreenSize';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { RestaurantGuardOutletContext } from '../routes/RestaurantGuard';

import styles from './LandingPage.module.scss';

interface LandingPageBookingOptionsProps {
	children?: ReactNode;
}

export const LandingPageBookingOptions = () => {
	const { featureFlags } = useOutletContext<RestaurantGuardOutletContext>();

	const [,, resetCarouselPosition] = useSessionStorage(CAROUSEL_SCROLL_POSITION_KEY, '');

	useEffect(() => {
		// Reset saved scroll position if entering from landing page
		resetCarouselPosition();
	}, []);

	/* Flip the landing tiles if feature flag is ON and there is no error fetching the flag */
	const shouldFlipTiles = Boolean(featureFlags?.flipLandingModal);

	return (
		<main className={styles.LandingPage}>
			{shouldFlipTiles ? (
				<ReversedLayout>
					<BookingOptionsHeader />
				</ReversedLayout>
			) : (
				<RegularLayout>
					<BookingOptionsHeader />
				</RegularLayout>
			)}
		</main>
	);
};

const RegularLayout = ({ children: header }: LandingPageBookingOptionsProps) => {
	const { isSmallScreen } = useScreenSize();
	const location = useLocation();

	const hideAltPlatform = isSmallScreen && location.pathname.endsWith('seating');

	const outletFlex = 1;

	const altPlatformFlex = 1;

	return (
		<>
			<div style={{ flex: outletFlex, position: 'relative' }}>
				{header}
				<BookWithTablz />
			</div>

			{hideAltPlatform ? null : (
				<div style={{ flex: altPlatformFlex }}>
					<BookWithAlternativePlatform />
				</div>
			)}
		</>
	);
};

const ReversedLayout = ({ children: header }: LandingPageBookingOptionsProps) => {
	const { isSmallScreen } = useScreenSize();
	const location = useLocation();

	const hideAltPlatform = isSmallScreen && location.pathname.endsWith('seating');

	const outletFlex = 1;

	const altPlatformFlex = 1;

	return (
		<>
			{hideAltPlatform ? null : (
				<div style={{ flex: altPlatformFlex, position: 'relative' }}>
					{header}
					<BookWithAlternativePlatform />
				</div>
			)}

			<div style={{ flex: outletFlex, overflow: 'hidden' }}>
				<BookWithTablz />
			</div>
		</>
	);
};
